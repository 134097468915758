<template>
    <div class="w-full card card-f-h">
        <ConfirmationModal @success="getAll" />

        <ModalUploadFile
            :visible="modalUploadFileVisible"
            @refresh="getAll"
            @close="modalUploadFileVisible = false"
        />

        <header class="flex justify-between items-center border-b border-solid border-gray-300 pb-4 mb-5">
            <h1>{{ $t('employee_details.files') }}</h1>
            <el-button type="primary" @click="showUploadModal">
                {{ $t('common.add') }}
            </el-button>
        </header>

        <el-table
            v-if="filesData.length > 0"
            v-loading="$wait.is('loading files')"
            :data="filesData"
            style="width: 100%"
            element-loading-spinner="el-custom-spinner"
        >
            <el-table-column prop="name" :label="$t('employee_details.name')" sortable :show-overflow-tooltip="true" />
            <el-table-column prop="uploaded_at" width="170" :label="$t('employee_details.upload_date')" sortable />
            <el-table-column width="75">
                <template slot-scope="scope">
                    <el-button-group>
                        <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.download')" placement="top">
                            <el-button type="primary" size="mini" @click="handleDownload(scope.row)">
                                <fa-icon :icon="['fas', 'download']" />
                            </el-button>
                            <!-- <a :href="scope.row.src">
                                <fa-icon :icon="['fas', 'download']" />
                            </a> -->
                        </el-tooltip>
                        <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.delete')" placement="top">
                            <el-button type="danger" size="mini" @click="handleDelete(scope.row.uuid)">
                                <fa-icon :icon="['fas', 'trash']" fixed-width />
                            </el-button>
                        </el-tooltip>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>

        <NoDataInformation :data="noDataInformation" waitKey="loading" />
    </div>
</template>
<script>
export default {
    components: {
        ModalUploadFile: () => import(/* webpackChunkName: "EmployeeFiles/ModalUploadFile" */ './ModalUploadFile'),
    },

    data() {
        return {
            noDataInformation:      false,
            employeeUuid:           this.$route.params.uuid,
            modalUploadFileVisible: false,
            filesData:              [],
        };
    },

    beforeCreate() {
        this.$emit('activeSubpage', 'files');
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading files');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${this.employeeUuid}/files`);
            this.filesData = data;
            this.noDataInformation = data.length;
            this.$wait.end('loading files');
        },

        async showUploadModal() {
            this.modalUploadFileVisible = true;
        },

        handleDownload(file) {
            window.open(`${process.env.VUE_APP_CORE_API_URL}/employees/file_download/${file.uuid}`);
        },

        async handleDelete(uuid) {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  `${process.env.VUE_APP_CORE_API_URL}/employees/employees/files/${uuid}`,
                actionType: 'destroy',
            });
        },
    },
};
</script>
